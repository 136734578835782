import Acura from "./Acura.png";
import AlfaRomeo from "./Alfa_Romeo.png";
import Audi from "./Audi.png";
import Bentley from "./Bentley.png";
import BMW from "./BMW.png";
import Buick from "./Buick.png";
import Cadillac from "./Cadillac.png";
import Chevrolet from "./Chevrolet.png";
import Chrysler from "./Chrysler.png";
import Dodge from "./Dodge.png";
import Fiat from "./Fiat.png";
import Ford from "./Ford.png";
import Genesis from "./Genesis.png";
import GMC from "./GMC.png";
import Honda from "./Honda.png";
import Hyundai from "./Hyundai.png";
import Infiniti from "./Infiniti.png";
import Jaguar from "./Jaguar.png";
import Jeep from "./Jeep.png";
import Kia from "./Kia.png";
import LandRover from "./Land_Rover.png";
import Lexus from "./Lexus.png";
import Lincoln from "./Lincoln.png";
import Maserati from "./Maserati.png";
import Mazda from "./Mazda.png";
import MercedesBenz from "./Mercedes_Benz.png";
import Mini from "./Mini.png";
import Mitsubishi from "./Mitsubishi.png";
import Nissan from "./Nissan.png";
import Porsche from "./Porsche.png";
import RAM from "./RAM.png";
import Smart from "./Smart.png";
import Subaru from "./Subaru.png";
import Tesla from "./Tesla.png";
import Toyota from "./Toyota.png";
import Volkswagen from "./Volkswagen.png";
import Volvo from "./Volvo.png";

let carLogos = {
  Acura,
  AlfaRomeo,
  Audi,
  Bentley,
  BMW,
  Buick,
  Cadillac,
  Chevrolet,
  Chrysler,
  Dodge,
  Fiat,
  Ford,
  Genesis,
  GMC,
  Honda,
  Hyundai,
  Infiniti,
  Jaguar,
  Jeep,
  Kia,
  LandRover,
  Lexus,
  Lincoln,
  Maserati,
  Mazda,
  MercedesBenz,
  Mini,
  Mitsubishi,
  Nissan,
  Porsche,
  RAM,
  Smart,
  Subaru,
  Tesla,
  Toyota,
  Volkswagen,
  Volvo,
};

export default carLogos;
